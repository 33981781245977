<template>
  <div>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-row class="mt-2 text-center">
          <v-col cols="12" sm="12" md="12">
            <v-btn
              v-if="importExcelTemplatePathName"
              color="blue darken-1"
              dark
              class="mb-2 mr-2"
              @click="downloadExcelTemplateGlobal(importExcelTemplatePathName)"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="mb-2 mr-2"
              :loading="isSelectingIdPOSperBrand"
              @click="onUploadExcelIdPOSperBrand"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonTextIdPOSperLabel }}
            </v-btn>
            <input
              ref="uploader_idpos_per_brand"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChangedIdPOSperBrand"
            />
            <v-btn
              color="warning"
              dark
              class="mb-2 mr-2"
              @click="downloadexcelposes"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :headers="posTableColumns"
              :items="posTableData"
              :items-per-page="10"
              :footer-props="{
                'items-per-page-options': [10, 50, 100],
              }"
              :options.sync="options"
              class="elevation-1"
            >
              <template
                v-for="(col, i) in filters"
                v-slot:[`header.${i}`]="{ header }"
              >
                <div
                  style="display: inline-block; padding: 16px 0;"
                  v-bind:key="i"
                >
                  <span>{{ header.text }}</span>
                </div>
                <div style="float: right; margin-top: 8px" v-bind:key="`A` + i">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-y
                    transition="slide-y-transition"
                    left
                    fixed
                    style="position: absolute; right: 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          :color="
                            activeFilters[header.value] &&
                            activeFilters[header.value].length <
                              filters[header.value].length
                              ? 'red'
                              : 'default'
                          "
                        >
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list flat dense class="pa-0">
                      <v-list-item-group
                        multiple
                        v-model="activeFilters[header.value]"
                        class="py-2"
                      >
                        <div class="search_input">
                          <v-text-field
                            hide-details
                            v-model="searchString[header.value]"
                            placeholder="Search ..."
                            class="pl-2 pr-2"
                            @input="searchItem(header.value)"
                          ></v-text-field>
                        </div>
                        <template v-for="(item, j) in filters[header.value]">
                          <v-list-item
                            v-bind:key="`A${j}`"
                            :value="item"
                            :ripple="false"
                          >
                            <template v-slot:default="{ active, toggle }">
                              <v-list-item-action>
                                <v-checkbox
                                  :input-value="active"
                                  :true-value="item"
                                  @click="toggle"
                                  color="primary"
                                  :ripple="false"
                                  dense
                                ></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="item"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-btn
                            text
                            block
                            @click="toggleAll(header.value)"
                            color="success"
                            >{{ $t("toggleall") }}</v-btn
                          >
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                            text
                            block
                            @click="clearAll(header.value)"
                            color="warning"
                            >{{ $t("clearall") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-menu>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";

export default {
  name: "messagePosTable",
  props: [
    "posData",
    "importExcelTemplatePathName",
    "apiurl_downloadexcelposes",
    "apiurl_uploadexcel",
    "getDataFromApi",
    "tableData",
    "createSelectedModel",
  ],
  data: function() {
    return {
      // postable in dialog
      labelPosArr: [],
      posTableData: [],
      tempdata: [],
      originalposTableData: [],
      options: {},
      filters: { id: [], name: [], status: [] },
      initFilterList: { id: [], name: [], status: [] },
      activeFilters: {},
      searchString: { id: "", name: "", status: "" },

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      isSelectingIdPOSperBrand: false,
      selectedFileIdposperBrand: null,
      defaultbuttonTextIdPOSperBrand: "Import",
    };
  },
  watch: {
    async posData(newVal, oldVal) {
      if (newVal) {
        this.init(newVal);
      }
    },
    // options: {
    //   handler() {
    //     this.sortChange();
    //   },
    //   deep: true,
    // },
  },
  computed: {
    buttonTextIdPOSperLabel() {
      return this.isSelectingIdPOSperBrand
        ? "Uploading..."
        : this.defaultbuttonTextIdPOSperBrand;
    },
    posTableColumns() {
      return [
        {
          text: "IdPOS",
          value: "id",
          width: "25%",
          filter: (value) => {
            return this.activeFilters.id
              ? this.activeFilters.id.includes(value)
              : true;
          },
        },
        {
          text: "POS " + this.$t("brands.name"),
          value: "name",
          width: "50%",
          filter: (value) => {
            return this.activeFilters.name
              ? this.activeFilters.name.includes(value)
              : true;
          },
        },
        {
          text: this.$t("salesforce.status"),
          value: "status",
          width: "25%",
          filter: (value) => {
            return this.activeFilters.status
              ? this.activeFilters.status.includes(value)
              : true;
          },
        },
      ];
    },
  },
  methods: {
    initFilters() {
      let keys = Object.keys(this.initFilterList);
      for (const key of keys) {
        this.searchString[key] = "";
        this.initFilterList[key] = this.posTableData
          .map((d) => {
            return d[key];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }
      this.filters = Object.assign({}, this.initFilterList);
      this.activeFilters = Object.assign({}, this.filters);
    },
    searchItem(col) {
      this.changeStr(col, this.searchString[col]);
    },
    changeStr(col, search_str) {
      if (search_str) {
        if (col === "id") {
          this.filters[col] = this.initFilterList[col].filter(
            (item) => item === parseInt(search_str)
          );
        } else {
          this.filters[col] = this.initFilterList[col].filter((item) =>
            item.toLowerCase().includes(search_str.toLowerCase())
          );
        }
      } else {
        this.filters[col] = this.initFilterList[col];
      }
      this.activeFilters = Object.assign({}, this.filters);
    },
    toggleAll(col) {
      this.activeFilters[col] = this.posTableData
        .map((d) => {
          return d[col];
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      this.searchString[col] = null;
      this.changeStr(col, null);
    },
    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sortPosTableChange() {
      let { sortBy, sortDesc } = this.options;
      if (sortBy && sortBy.length > 0) {
        sortBy = sortBy[0];
        sortDesc = sortDesc[0];
        if (sortDesc) {
          this.tempdata = _.sortBy(this.tempdata, sortBy).reverse();
        } else {
          this.tempdata = _.sortBy(this.tempdata, sortBy);
        }
        this.posTableData = this.tempdata;
      }
    },
    initTable(newVal) {
      this.posTableData = newVal;
      this.originalposTableData = newVal;
      this.tempdata = newVal;
      this.initFilters();
    },
    initPosTable(data) {
      this.initTable(data);
    },
    onUploadExcelIdPOSperBrand() {
      this.isSelectingIdPOSperBrand = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingIdPOSperBrand = false;
        },
        { once: false }
      );
      this.$refs.uploader_idpos_per_brand.click();
    },
    async onFileChangedIdPOSperBrand(e) {
      this.selectedFileIdposperBrand = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingIdPOSperBrand = true;
        let data = new FormData();
        data.append("file", this.selectedFileIdposperBrand);
        this.$refs.uploader_idpos_per_brand.value = "";
        try {
          const response = await ApiService.post(
            this.apiurl_uploadexcel,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`,
              },
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message,
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
            await this.getDataFromApi();
            let selectedIndex = this.tableData.findIndex(
              (el) => el.id === this.posData.id
            );
            let updated = await this.createSelectedModel(
              this.tableData[selectedIndex]
            );
            console.log("updated- ", updated);
            this.init(updated);
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingIdPOSperBrand = false;
        } catch (error) {
          console.log(error);
          this.isSelectingIdPOSperBrand = false;
        }
      }
    },
    async downloadexcelposes() {
      let fetch_url = this.apiurl_downloadexcelposes;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "data.xlsx");
    },
    init(newVal) {
      this.initPosTable(newVal.pos);
    },
  },
  async updated() {},
  async mounted() {
    this.init(this.posData);
  },
};
</script>

<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"color":_vm.snackbar_class,"elevation":"24"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text_snackbar)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-row',{staticClass:"mt-2 text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.importExcelTemplatePathName)?_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"blue darken-1","dark":""},on:{"click":function($event){return _vm.downloadExcelTemplateGlobal(_vm.importExcelTemplatePathName)}}},[_c('b-icon',{attrs:{"icon":"download","aria-hidden":"true"}}),_vm._v(" Plantilla Import ")],1):_vm._e(),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"success","dark":"","loading":_vm.isSelectingIdPOSperBrand},on:{"click":_vm.onUploadExcelIdPOSperBrand}},[_c('b-icon',{attrs:{"icon":"file-excel-fill","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.buttonTextIdPOSperLabel)+" ")],1),_c('input',{ref:"uploader_idpos_per_brand",staticClass:"d-none",attrs:{"type":"file","accept":".xls,.xlsx"},on:{"change":_vm.onFileChangedIdPOSperBrand}}),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"warning","dark":""},on:{"click":_vm.downloadexcelposes}},[_c('b-icon',{attrs:{"icon":"file-excel-fill","aria-hidden":"true"}}),_vm._v(" Export ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.posTableColumns,"items":_vm.posTableData,"items-per-page":10,"footer-props":{
              'items-per-page-options': [10, 50, 100],
            },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.filters),function(col,i){return {key:`header.${i}`,fn:function({ header }){return [_c('div',{key:i,staticStyle:{"display":"inline-block","padding":"16px 0"}},[_c('span',[_vm._v(_vm._s(header.text))])]),_c('div',{key:`A` + i,staticStyle:{"float":"right","margin-top":"8px"}},[_c('v-menu',{staticStyle:{"position":"absolute","right":"0"},attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":"","transition":"slide-y-transition","left":"","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.activeFilters[header.value] &&
                          _vm.activeFilters[header.value].length <
                            _vm.filters[header.value].length
                            ? 'red'
                            : 'default'}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0",attrs:{"flat":"","dense":""}},[_c('v-list-item-group',{staticClass:"py-2",attrs:{"multiple":""},model:{value:(_vm.activeFilters[header.value]),callback:function ($$v) {_vm.$set(_vm.activeFilters, header.value, $$v)},expression:"activeFilters[header.value]"}},[_c('div',{staticClass:"search_input"},[_c('v-text-field',{staticClass:"pl-2 pr-2",attrs:{"hide-details":"","placeholder":"Search ..."},on:{"input":function($event){return _vm.searchItem(header.value)}},model:{value:(_vm.searchString[header.value]),callback:function ($$v) {_vm.$set(_vm.searchString, header.value, $$v)},expression:"searchString[header.value]"}})],1),_vm._l((_vm.filters[header.value]),function(item,j){return [_c('v-list-item',{key:`A${j}`,attrs:{"value":item,"ripple":false},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"true-value":item,"color":"primary","ripple":false,"dense":""},on:{"click":toggle}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)]}}],null,true)})]})],2),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"text":"","block":"","color":"success"},on:{"click":function($event){return _vm.toggleAll(header.value)}}},[_vm._v(_vm._s(_vm.$t("toggleall")))])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"text":"","block":"","color":"warning"},on:{"click":function($event){return _vm.clearAll(header.value)}}},[_vm._v(_vm._s(_vm.$t("clearall")))])],1)],1)],1)],1)],1)]}}})],null,true)})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }